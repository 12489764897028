<template>
  <div class="app">
    <div
      id="page" 
      class="page" 
    >
      <Header />
      <Home />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Home from './components/Home.vue'

 

export default {
  name: 'app',
  components: {
    Header,
    Home
  },
  data: function() {
    return {
      logoHidden: false
    }
  },
  mounted: function() {
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
  },
  computed: {
    
  },
  methods: {
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    hideLogo() {
      this.logoHidden = true;
    },
    showLogo() {
      this.logoHidden = false;
    }
  }
}
</script>
