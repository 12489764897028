<template>
	<header :class="['header', {'loaded': loaded}, {'moved': $parent.logoHidden}]">
		<img src="./../assets/img/logo.svg" :class="['logo-img', {'active': $parent.logoHidden}]">
		<a :class="['desc', {'moved': $parent.logoHidden}]" @click="copyEmail">
			<img src="./../assets/img/copy.svg" class="link-img">
			<span v-if="!copied">hey@nkd.studio</span>
			<span v-else>Email Copied!</span>
		</a>
	</header>
</template>

<script>

export default {
	name: 'Header',
	props: [],
	data: function() {
		return {
			loaded: false,
			copied: false
		}
	},
	methods: {
		copyEmail() {
			let self = this;
			navigator.clipboard.writeText('hey@nkd.studio').then(function() {
				self.copied = true;
			}).catch(function() {
				self.copied = true;
			});
			setTimeout(function() {
				self.copied = false;
			}, 1000);
		}
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.loaded = true;
		}, 100);
	}
}
</script>
