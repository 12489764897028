
import Vue from 'vue'
import App from './App.vue'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import './../node_modules/slick-carousel/slick/slick.css';
import './assets/css/style.scss'
import 'promise-polyfill/src/polyfill';

new Vue({
	render: h => h(App),
}).$mount('#app')
